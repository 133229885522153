<template>
  <header class="menu row">
    <div class="menu__wrapper">
      <div class="menu__item menu__item--left">
        <img
          class="menu-burger"
          src="@/assets/img/pictos/burger.svg"
          alt="Picto navigation"
          @click.prevent="openMenu"
        />

        <transition name="fade">
          <WidddiNav :admin="isAdmin" :isOpen="isOpen" @close="closeMenu" />
        </transition>
      </div>
      <div class="menu__item">
        <router-link to="/" tag="a">
          <img
            src="@/assets/img/pictos/logo-blanc-widddi.svg"
            class="logo"
            alt="Logo Widddi"
          />
        </router-link>
      </div>
      <div class="menu__item  menu__item--right">
        <nav class="nav-top-picto">
          <router-link to="/panier" tag="a">
            <span class="cart-badge" v-if="cart.length > 0">{{ cart.length }}</span>
            <img src="@/assets/img/pictos/panier.svg" alt="Picto panier" />
            <span>Panier</span>
          </router-link>
          <router-link :to="{ name: 'shipping-admin' }" tag="a" v-if="isAdmin">
            <img src="@/assets/img/pictos/compte.svg" alt="Picto mon compte" />
            <span>Admin</span>
          </router-link>
          <router-link :to="{ name: 'Profile' }" tag="a" v-else>
            <img src="@/assets/img/pictos/compte.svg" alt="Picto mon compte" />
            <span>Mon compte</span>
          </router-link>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { WidddiNav } from '../Navigation';

export default {
  components: { WidddiNav },

  props: {
    cart: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    scrollAmount: {
      handler(value) {
        this.isSmall = value > 50;
      },
    },
  },

  data() {
    return {
      scrollAmount: 0,
      isSmall: false,
      isOpen: false,
    };
  },

  computed: {
    ...mapGetters({
      isAdmin: 'app/isAdmin',
    }),
  },

  methods: {
    handleScroll() {
      if (!this.locked) {
        this.scrollAmount = window.scrollY;
      }
    },

    openMenu() {
      this.isOpen = true;
    },

    closeMenu() {
      this.isOpen = false;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.isSmall = this.small;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
