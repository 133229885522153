<template>
  <div v-if="src.length" class="thumbnail" ref="thumbnail" @click="zoom">
    <img class="thumbnail__image" :src="src" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      touched: false,
    };
  },

  methods: {
    zoom() {
      const el = this.$refs.thumbnail;
      if (!this.touched) {
        el.classList.add('thumbnail--zoom-out');
        this.touched = true;
      }

      el.classList.toggle('thumbnail--zoom');
    },
  },
};
</script>
