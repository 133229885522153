<template>
  <div class="socials">
    <div class="socials__phrase" v-if="hasSlotData">
      <slot />
    </div>

    <nav class="socials__navigation">
      <a href="https://www.facebook.com/widddi3d" target="_blank">
        <img src="@/assets/img/pictos/facebook.svg" alt="Picto Facebook">
      </a>
      <a href="https://www.instagram.com/widddi3d" target="_blank">
        <img src="@/assets/img/pictos/instagram.svg" alt="Picto Instagram">
      </a>
      <a href="https://pinterest.fr/widddi3d" target="_blank">
        <img src="@/assets/img/pictos/pinterest.svg" alt="Picto Pinterest">
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'WidddiSocials',

  computed: {
    hasSlotData() {
      return this.$slots.default;
    },
  },
};
</script>
