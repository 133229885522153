<template>
  <div class="hello" v-resize:debounce="onResize">
    <WidddiHeader :cart="cart" />
    <section :class="className" v-if="cloud">
      <slot name="cloud">
        <div class="big-gradient"></div>
      </slot>
      <div class="cloud"></div>
    </section>

    <slot name="body" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import resize from 'vue-resize-directive';
import { WidddiHeader, WidddiFooter } from './Elements';

export default {
  directives: { resize },

  props: {
    cloud: {
      type: Boolean,
      default: false,
    },
    space: {
      type: Boolean,
      default: false,
    },
    instagram: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => ['widddi3d'],
    },
  },

  components: {
    WidddiHeader,
    WidddiFooter,
  },

  computed: {
    ...mapGetters({
      cart: 'shop/cart',
    }),
    className() {
      const className = 'cloud-container  big-container';

      return className;
    },
  },

  methods: {
    toRoute(name = 'Checkout') {
      this.$router.push({ name });
    },

    onResize(e) {
      this.$emit('resize', e);
    },
  },
};
</script>
