<template>
  <div class="inspiration row">
    <div class="inspiration__title col-xs-12">
      <slot name="title" />
    </div>

    <div class="inspiration__content col-xs-12 row">
      <div
        v-for="inspiration in flux"
        class="col-md-3 col-sm-4 col-xs-6 col-space"
        :key="inspiration.id"
      >
        <img :src="`${url}${format(inspiration.media)}`" alt="" />

        <ul class="tleft basic-list">
          <li v-for="(info, i) in inspiration.couleurs" :key="i">
            <p class="tleft">
              <span class="color-bullet" :class="info.slug"></span>
              {{ info.title }}
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="!hide" class="inspiration__link">
      <router-link
        :to="{ name: 'colors' }"
        class="btn btn-border"
        target="_blank"
      >
        Voir toutes les photos par couleur
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import { mapGetters } from 'vuex';

const DEFAULT_COUNT = 12;

export default {
  props: {
    tags: { type: Array, default: () => [] },
    colors: { type: Array, default: () => [] },
    count: { type: Number, default: DEFAULT_COUNT },
    additive: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    removeNoColor: { type: Boolean, default: false },
  },

  data() {
    return {
      flux: [],
    };
  },

  async created() {
    this.getFlux();
  },

  computed: {
    ...mapGetters({
      url: 'app/cms',
    }),
  },

  methods: {
    format(media) {
      return (
        (media.formats.small && media.formats.small.url) ||
        (media.formats.medium && media.formats.medium.url) ||
        (media.formats.large && media.formats.large.url)
      );
    },

    async getFlux() {
      const filterAnd = [];
      const filterOr = [];

      const tags = this.tags.map(tag => ({ 'tags.slug': tag }));

      filterAnd.push(tags);

      if (this.additive && this.colors.length > 0) {
        const colors = this.colors.map(color => ({
          'couleurs.slug': color,
        }));

        filterOr.push(...colors);
      }

      if (this.removeNoColor) {
        filterAnd.push({ couleurs_null: false });
      }

      let where = filterAnd;

      if (filterOr.length > 0) {
        where = {
          _or: filterOr.map(condition => filterAnd.concat(condition)),
        };
      }

      const query = qs.stringify({
        _where: where,
        random: true,
      });

      const { data: flux } = await axios.get(
        `${this.url}/inspirations?${query}`,
      );

      this.flux = flux.splice(0, this.count || DEFAULT_COUNT);
    },
  },

  watch: {
    colors: {
      handler() {
        this.getFlux();
      },
    },
  },
};
</script>
