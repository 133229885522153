<template>
  <div>
    <div id="steps" ref="steps" class="choose-step">
      <button
        v-for="(step, index) in steps"
        class="choose-step__button"
        :class="customClass(index)"
        :id="'step-' + index"
        :key="index"
        @click.prevent="changeStep(index)"
      >
        <span class="choose-step__step">
          {{ step.number }}
        </span>
      </button>
    </div>

    <h1 class="choose-step__active">
      {{ steps[activeStep].name }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Array,
      default: () => [],
    },

    activeStep: {
      type: Number,
      default: null,
    },

    photoOption: {
      type: Boolean,
      default: undefined,
    },

    versionOption: {
      type: Boolean,
      default: undefined,
    },

    force: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasVersionOption() {
      return this.versionOption !== undefined;
    },

    hasPhotoOption() {
      return this.photoOption !== undefined;
    },
  },

  methods: {
    changeStep(step) {
      this.$emit('set-active', step);
    },

    customClass(step) {
      const className = ['tleft'];

      if (step === this.activeStep) {
        className.push('active');
      }

      if (
        this.disabled.includes(step) &&
        ((this.hasPhotoOption && !this.photoOption) ||
          (this.hasVersionOption && !this.versionOption) ||
          this.force)
      ) {
        className.push('disabled');
      }

      return className;
    },
  },
};
</script>
