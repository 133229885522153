<template>
  <section>
    <WidddiCookie />
    <section class="col-big-vspace footer-nav bg-silver">
      <ul class="list-classic">
        <li
          v-for="(link, index) in footerLink"
          :key="index"
        >
          <p class="black bold">
            <router-link
              :to="{ name: link.name}"
              :target="link.name === 'cgu' ? '_blank' : ''"
            >
              {{ link.text }}
            </router-link>
          </p>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import WidddiCookie from '../../external/Cookie';

export default {

  components: { WidddiCookie },

  data() {
    return {
      footerLink: [
        {
          name: 'cgu',
          text: 'CGV',
        },
        {
          name: 'faq',
          text: 'FAQ',
        },
        {
          name: 'contact',
          text: 'Contact',
        },
      ],
    };
  },

};
</script>
