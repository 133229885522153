<template>
  <div class="cookie col-vspace" v-if="showCookie">
    <span class="tnormal">
      En cliquant sur "J’accepte", vous acceptez l’utilisation des cookies à des fins
      techniques et d'analyse du trafic.
    </span>
    <button class="btn btn-valid btn-small " @click="setCookie(1)">J'accepte</button>
    <button class="btn btn-border btn-small" @click="setCookie(0)">
      Je refuse
    </button>
  </div>
</template>

<script>
export default {
  name: 'widddi-cookie',
  data() {
    return {
      showCookie: false,
    };
  },
  methods: {
    setCookie(value = 1) {
      localStorage.setItem('cookie', value);
      this.showCookie = false;
    },
  },
  mounted() {
    if (!parseInt(localStorage.getItem('cookie'), 10)) {
      this.showCookie = true;
    }
  },
};
</script>
